export const initialValues = {
  company: '',
  phone: '',
  email: '',
  location: '',
  city: '',
  state: '',
  county: '',
  zip: '',
  locationUSA: [],
  employees: '',
  whatsapp: true,
  firstTime: '',
  sumInsuredType: '',
  sumInsured: '1 Lac',
  coverageType: '',
  totalNumberOfEmployees: 0,
  employeeCount35: 0,
  employeeCount45: 0,
  employeeCount55: 0,
  employeeCount65: 0,
  spouseCount35: 0,
  spouseCount45: 0,
  spouseCount55: 0,
  spouseCount65: 0,
  parentsCount45: 0,
  parentsCount55: 0,
  parentsCount65: 0,
  parentsCount66: 0,
  totalKids: 0,
  expiryDate: '',
};

export const initialValuesCalc = {
  averageAge: '',
  annualTurnover: '',
};